/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import style from "./layout.module.scss";
import favicon from "../favicon.svg";

const Layout = ({ children }) => {
  return (
    <>
      <Helmet
        link={[{ rel: "icon", type: "image/svg+xml", href: `${favicon}` }]}
      />
      <main className={style.main}>{children}</main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
